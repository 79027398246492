<template>
  <section>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <!-- end appbar nav icon -->
      <v-toolbar-title class="headline font-weight-semibold"
        >Organisations ({{ listMeta.total }})</v-toolbar-title
      >

      <!-- end toolbar title -->
      <v-spacer />
      <!-- end -->
      <v-row v-show="showSearch" align="center" style="max-width: 370px">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search organisation name"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <v-btn class="ml-5" icon @click="showSearchField">
        <v-progress-circular
          indeterminate
          v-if="isLoading"
        ></v-progress-circular>

        <img src="@/assets/icons/search.svg" alt height="17.7px" v-else />
      </v-btn>
    </v-app-bar>
    <!-- end app bar -->

    <div
      id="organisation-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="isLoading"
      infinite-scroll-distance="100"
    >
      <v-data-table
        :headers="headers"
        :items="organisations"
        multi-sort
        hide-default-footer
        disable-pagination
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @update:sort-desc="sortTable"
      >
        <template v-slot:body="{ items: organisations }">
          <tr
            v-for="organisation in organisations"
            :key="organisation.id"
            class="clickable"
            @click="
              $router.push({
                name: 'organisation.details',
                params: { id: organisation.id },
              })
            "
            height="75px"
          >
            <td>{{ organisation.name }}</td>
            <td>{{ organisation.addedDate }}</td>
            <td>{{ organisation.lastUpdated }}</td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <v-btn
      color="primary"
      id="add-organisation-button"
      large
      dark
      rounded
      elevation="16"
      @click="
        $router.push({
          name: 'organisation.new',
        })
      "
    >
      <v-icon>
        {{ icons.plus }}
      </v-icon>
      <span class="ml-2">Add Organisation</span>
    </v-btn>
  </section>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import { mapActions, mapMutations, mapState } from 'vuex'
import { mdiAccountGroup, mdiPlusCircleOutline } from '@mdi/js'
import debounce from 'lodash/debounce'

export default {
  name: 'OrganisationsPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      showSearch: false,
      showForm: false,
      isLoading: false,
      filter: {
        search: '',
      },
      icons: {
        users: mdiAccountGroup,
        plus: mdiPlusCircleOutline,
      },
      sortBy: [],
      sortDesc: [],
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          width: '40%',
        },
        {
          text: 'Added date',
          value: 'addedDate',
          sortable: true,
          width: '30%',
        },
        {
          text: 'Last update',
          value: 'lastUpdated',
          sortable: false,
          width: '30%',
        },
      ],
    }
  },

  mounted() {
    this.clearOrganisations()
    this.fetchOrganisations()
  },

  computed: {
    ...mapState({
      organisations: (state) => state.organisation.list,
      listMeta: (state) => state.organisation.listMeta,
    }),
  },

  methods: {
    ...mapActions({
      getOrganisations: 'organisation/getOrganisations',
    }),

    ...mapMutations({
      clearOrganisations: 'organisation/clearOrganisationList',
    }),

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchOrganisations(this.listMeta.current_page + 1)
      }
    },

    async fetchOrganisations(page = 1) {
      if (!this.isLoading) {
        let params = { page }

        if (this.sortBy) {
          let sort = this.sortBy.map(function (sort, index) {
            if (sort === 'addedDate') {
              sort = 'created_at'
            }

            if (this[index] === true) {
              return '-' + sort
            }

            return sort
          }, this.sortDesc)

          params.sort = sort
        }

        if (this.filter.search) {
          params.search = this.filter.search
        }

        this.isLoading = true
        await this.getOrganisations(params)
        this.isLoading = false
      }
    },

    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },

    search: debounce(function () {
      this.clearOrganisations()
      this.fetchOrganisations()
    }, 600),

    async sortTable() {
      let params = { page: 1 }

      if (this.sortBy) {
        let sort = this.sortBy.map(function (sort, index) {
          if (sort === 'addedDate') {
            sort = 'created_at'
          }

          if (this[index] === true) {
            return '-' + sort
          }

          return sort
        }, this.sortDesc)

        params.sort = sort
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.isLoading = true
      await this.getOrganisations(params)
      this.isLoading = false
    },
  },
}
</script>

<style scoped>
#add-organisation-button {
  position: fixed;
  bottom: 64px;
  right: 64px;
}
</style>

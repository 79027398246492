<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <router-link
          class="text-decoration--none"
          :to="{ name: 'organisations' }"
          ><div class="div-user">Organisation</div></router-link
        >
        <v-icon>{{ icons.arrowRight }}</v-icon>
        Add New Organisation
      </v-toolbar-title>
    </v-app-bar>

    <OrganisationForm />
  </div>
</template>

<script>
import { mdiChevronRight } from '@mdi/js'
import { mapState } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import OrganisationForm from './components/OrganisationForm'

export default {
  name: 'UserDetails',

  components: {
    AppBarNavIcon,
    OrganisationForm,
  },

  data() {
    return {
      icons: {
        arrowRight: mdiChevronRight,
      },
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.user.userDetails,
    }),
  },
}
</script>

<style scoped>
.div-user {
  color: black;
}
</style>
